import React, { useState, useRef, Dispatch } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { SetStateAction } from "jotai";

// 컴포넌트가 받을 props 타입 정의
interface MonthsSelectorProps {
  label: string; // "이용 개월 선택" 등 표시할 라벨
  highlightColor?: string; // 최대/최소 도달 시 버튼/텍스트에 적용할 색상
  month: number;
  setMonth: Dispatch<SetStateAction<number>>;
}

export function MonthsSelector({
  label,
  highlightColor = "#ff0000",
  month,
  setMonth,
}: MonthsSelectorProps) {
  // 증감 interval 레퍼런스
  const incrementIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const decrementIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null
  );

  // + 버튼 누르기 시작
  const startIncrement = () => {
    // 1) 먼저 1회 즉시 증가
    setMonth((prev) => Math.min(prev + 1, 12));
    // 2) 일정 간격으로 반복 증가
    incrementIntervalRef.current = setInterval(() => {
      setMonth((prev) => Math.min(prev + 1, 12));
    }, 150); // 숫자가 클수록 느려짐(밀리초)
  };

  // + 버튼 떼거나 / 영역에서 벗어날 때
  const stopIncrement = () => {
    if (incrementIntervalRef.current) {
      clearInterval(incrementIntervalRef.current);
      incrementIntervalRef.current = null;
    }
  };

  // - 버튼 누르기 시작
  const startDecrement = () => {
    // 1) 먼저 1회 즉시 감소
    setMonth((prev) => Math.max(prev - 1, 0));
    // 2) 일정 간격으로 반복 감소
    decrementIntervalRef.current = setInterval(() => {
      setMonth((prev) => Math.max(prev - 1, 0));
    }, 150);
  };

  // - 버튼 떼거나 / 영역에서 벗어날 때
  const stopDecrement = () => {
    if (decrementIntervalRef.current) {
      clearInterval(decrementIntervalRef.current);
      decrementIntervalRef.current = null;
    }
  };

  // 현재 month가 0이면 최저치 도달, 12면 최대치 도달
  const isMin = month === 0;
  const isMax = month === 12;

  return (
    <Box sx={{ width: "100%", textAlign: "start" }}>
      {/* props로 받은 label 표시 */}
      <Typography
        variant="caption"
        sx={{ fontWeight: "bold", mb: 1, textAlign: "start" }}
      >
        {label}
      </Typography>

      {/* 버튼 & 개월수 표시 영역 */}
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        {/* - 버튼 */}
        <IconButton
          // 최저치라면(- 버튼) 지정 색상 표시
          sx={{
            color: !isMin ? highlightColor : "inherit",
          }}
          onMouseDown={startDecrement}
          onMouseUp={stopDecrement}
          onMouseLeave={stopDecrement}
        >
          <Remove />
        </IconButton>

        {/* 현재 선택된 개월 표시 */}
        <Typography sx={{ flexGrow: 1, textAlign: "center" }}>
          {month}개월
        </Typography>

        {/* + 버튼 */}
        <IconButton
          // 최대치라면(+ 버튼) 지정 색상 표시
          sx={{
            color: !isMax ? highlightColor : "inherit",
          }}
          onMouseDown={startIncrement}
          onMouseUp={stopIncrement}
          onMouseLeave={stopIncrement}
        >
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
}
