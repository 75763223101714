import { ServiceType } from "../Enum/BillingTypeEnum";

export const usePayOptions = () => {
  return {
    payTabName: [
      ServiceType.CAREER_PALNNER,
      ServiceType.TEAMPL,
      ServiceType.DOKGABI,
    ],
    initializeDokgabiPaymentSetting: [
      {
        service: ServiceType.DOKGABI,
        payment: {
          view_value: "Lite",
          value: "lite",
        },
        duration: 1,
        price: 10000,
        description:
          "기본적인 챗봇 운영이 가능하며, 소규모 사용자에게 적합한 플랜입니다.",
        features: [
          "1개 챗봇 생성 가능",
          "최대 30명의 사용자 채팅 지원",
          "1명의 관리자 권한 제공",
          "무료 챗봇 1개 제공",
        ],
      },
      {
        service: ServiceType.DOKGABI,
        payment: {
          view_value: "Essential",
          value: "essential",
        },
        duration: 1,
        price: 150000,
        description:
          "챗봇을 보다 효과적으로 운영할 수 있도록 추가 기능과 리소스를 제공합니다.",
        features: [
          "2개 챗봇 생성 가능",
          "최대 30명의 사용자 채팅 지원",
          "📄 최대 엑셀/PDF 60개 챗봇 학습 지원",
          "3명의 관리자 권한 제공",
          "무료 챗봇 1개 제공",
          "무료 캐릭터 말투 31개 제공",
          "무료 Asset 세트 31개 제공",
        ],
      },
      {
        service: ServiceType.DOKGABI,
        payment: {
          view_value: "Pro",
          value: "pro",
        },
        duration: 1,
        price: 600000,
        description:
          "중소규모 이상의 팀을 위한 플랜으로, 다수의 사용자 및 관리자가 협업할 수 있도록 설계되었습니다.",
        features: [
          "2개 챗봇 생성 가능",
          "최대 120명의 사용자 채팅 지원",
          "📄 최대 엑셀/PDF 250개 챗봇 학습 지원",
          "3명의 관리자 권한 제공",
          "무료 챗봇 1개 제공",
          "무료 캐릭터 말투 31개 제공",
          "무료 Asset 세트 31개 제공",
          "단독 호스팅 가능 (유료)",
          "온보딩 기술지원 포함 (600,000원/월)",
        ],
      },
      {
        service: ServiceType.DOKGABI,
        payment: {
          view_value: "Premium",
          value: "premium",
        },
        duration: 1,
        price: 2000000,
        description:
          "대규모 기업 환경에서도 안정적인 챗봇 운영이 가능하도록 모든 기능을 제공합니다.",
        features: [
          "4개 챗봇 생성 가능",
          "최대 500명의 사용자 채팅 지원",
          "📄 최대 엑셀/PDF 1000개 챗봇 학습 지원",
          "5명의 관리자 권한 제공",
          "무료 챗봇 1개 제공",
          "무료 캐릭터 말투 31개 제공",
          "무료 Asset 세트 56개 제공",
          "단독 호스팅 가능 (유료)",
          "온보딩 기술지원 및 서비스 지원 포함 (600,000원/월)",
        ],
      },
    ],
    initializeCareerplannerPaymentSetting: [
      {
        service: ServiceType.CAREER_PALNNER,
        payment: {
          view_value: "Basic",
          value: "basic",
        },
        duration: 1,
        people: 1,
        price: 10000,
        description: "기본적인 진로 탐색 및 챗봇 기능을 사용할 수 있습니다.",
        features: [
          "시그니처카드 1개 발급",
          "대화 수 100개, 채팅방 1개 개설",
          "AI 진로컨설턴트(엘리스) 지원",
          "커리어플랜 + 루틴 개설",
          "크루 공유",
          "기본 대시보드 기능 제공 (진로 탐색, 추천 직업, 일정 캘린더 포함)",
        ],
      },
      {
        service: ServiceType.CAREER_PALNNER,
        payment: {
          view_value: "Standard",
          value: "standard",
        },
        duration: 1,
        people: 1,
        price: 29800,
        description:
          "더 많은 사용자와 함께 효율적으로 계획을 세우고 협업할 수 있습니다.",
        features: [
          "시그니처카드 3개 발급",
          "대화 수 300개, 채팅방 3개 개설",
          "대화 내용 검색 및 AI 진로컨설턴트(엘리스) 지원",
          "AI 멘토 기능 추가",
          "연관 직업 추천 기능 추가",
          "크루 공유 + 크루 추천 기능",
          "루틴 개설 및 할 일 관리",
          "플랜 피드백 제공",
          "CO-ESSAY 기능 제공",
          "전체 대시보드 기능 지원 (진로 탐색, 추천 직업, 일정 캘린더 포함)",
        ],
      },
      {
        service: ServiceType.CAREER_PALNNER,
        payment: {
          view_value: "Professional",
          value: "professional",
        },
        duration: 1,
        people: 1,
        price: 150000,
        description:
          "대규모 팀 및 전문가를 위한 맞춤형 플랜으로 모든 기능을 제공합니다.",
        features: [
          "시그니처카드 5개 발급",
          "대화 수 1500개, 채팅방 5개 개설",
          "AI 진로컨설턴트 + AI 멘토 지원",
          "연관 직업 추천 + 크루 추천 기능",
          "CO-ESSAY 기능 제공",
          "설문조사 및 투표 기능 포함",
          "대시보드 기능 지원 (진로 탐색, 추천 직업, 일정 캘린더 포함)",
          "대시보드 기능 + 리서치 통계 기능 추가",
          "플랜 피드백 및 데이터 분석",
          "맞춤형 커리어 추천 기능",
          "커리어 맞춤형 학습 리소스 제공",
          "최상의 협업을 위한 모든 CareerPlanner 기능 제공",
        ],
      },
    ],
    initializeTeamplPaymentSetting: [
      {
        service: ServiceType.TEAMPL,
        payment: {
          view_value: "Beginner",
          value: "beginner",
        },
        duration: 1,
        people: 1,
        price: 10000,
        description:
          "기본적인 일정 관리 및 소셜 크루 기능을 사용할 수 있습니다.",
        features: [
          "피드 + 간트차트 + 캘린더 일체형 일정관리",
          "소셜 크루 기능 제공",
          "기본 대시보드 기능 제공",
          {
            function: "문의하기",
            label:
              "3명이상 팀단위 사용시 할인적용되니 문의해주세요.",
          },
        ],
      },
      {
        service: ServiceType.TEAMPL,
        payment: {
          view_value: "Intermediate",
          value: "intermediate",
        },
        duration: 1,
        people: 1,
        price: 29800,
        description:
          "더 많은 사용자와 함께 효율적으로 계획을 세우고 협업할 수 있습니다.",
        features: [
          "피드 + 간트차트 + 캘린더 일체형 일정관리",
          "대화 수 300개, 채팅방 1개 개설",
          "AI 선배 챗봇 지원 및 대화 내용 검색",
          "소셜 크루 기능 제공",
          "전체 대시보드 기능 제공",
          "FAQ 및 업무매뉴얼을 어셋으로 제작 및 학습 가능",
          {
            function: "문의하기",
            label:
              "Intermediate 플랜 구매자 중 Advanced 기능을 단기로 사용 원하시면 문의해주세요.",
          },
        ],
      },
      {
        service: ServiceType.TEAMPL,
        payment: {
          view_value: "Advanced",
          value: "advanced",
        },
        duration: 1,
        people: 1,
        price: 150000,
        description:
          "대규모 팀 및 전문가나 연구 협업을 위한 맞춤형 플랜으로 모든 기능을 제공합니다.",
        features: [
          "피드 + 간트차트 + 캘린더 일체형 일정관리",
          "대화 수 1500개, 채팅방 5개 개설",
          "대화 내용 검색 및 AI 선배 챗봇 지원",
          "소셜 크루 기능 제공",
          "대화형 AI 챗봇과 함께 연구 주제 탐색, 토론, 서베이, 보고서 작성 가능",
          "조사리포트, 설문조사보고서, 연구보고서, 업무매뉴얼 등을 어셋으로 제작 및 학습 가능",
          "공동 협업 시 버전 관리 및 원본 복원 가능",
          "최상의 협업 환경을 위한 다양한 템플릿과 기능 제공",
          "전체 대시보드 기능 지원",
          "연구 및 프로젝트 통계 대시보드 기능 추가 가능",
          "리서치 통계 기능 추가",
          "최상의 협업을 위한 모든 Teampl 기능 제공",
        ],
      },
    ],
  };
};
