import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";
import { MonthsSelector } from "../MouthSelector";
// jotai 관련 import
import { dokgabiPaymentSettingListAtom } from "../../../Atoms/PlanTableAtom";
import { useAtom } from "jotai";
import { Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import { handlePurchaseDokgabiSingleButtonAtom } from "../../../ViewModels/PlanTableViewModel";
import { dokgabiUserAtom } from "../../../Atoms/PublicAtom";
import { useNavigate } from "react-router-dom";
import { DokgabiPlanTable } from "./DokgabiPlanTable";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 320,
  minHeight: 500,
  display: "flex", // 카드 전체를 flex 컨테이너로
  flexDirection: "column", // 세로 배치
  height: "100%",
  textAlign: "center",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  padding: 0,
  border: "1px solid #ddd",
  borderRadius: "12px",
}));

export function DokgabiPlan() {
  const [user] = useAtom(dokgabiUserAtom);
  // Atom에서 plans 배열과 setter를 가져옵니다.
  const [plans, setPlans] = useAtom(dokgabiPaymentSettingListAtom);

  const [, handlePurchaseDokgabiSingleButton] = useAtom(
    handlePurchaseDokgabiSingleButtonAtom
  );

  const [openDetail, setOpenDetail] = useState(false);

  const membershipRanking = ["lite", "essential", "pro", "premium"];

  // 특정 플랜(index)의 duration만 업데이트하는 setter 함수 생성
  const handleSetMonth = (index: number) => {
    // MonthsSelector에서 'setMonth((prev) => prev + 1)' 형태로 호출 가능하게
    return (valueOrUpdater: number | ((prevValue: number) => number)) => {
      setPlans((prev) => {
        const newArr = [...prev];
        // 기존 duration
        const oldDuration = newArr[index].duration;

        // updater가 함수인지( (prev)=>... ) 아니면 숫자인지에 따라 처리
        let newDuration = 0;
        if (typeof valueOrUpdater === "function") {
          newDuration = (valueOrUpdater as (prev: number) => number)(
            oldDuration
          );
        } else {
          newDuration = valueOrUpdater;
        }

        // 해당 플랜만 duration 업데이트
        newArr[index] = {
          ...newArr[index],
          duration: newDuration,
        };
        return newArr;
      });
    };
  };

  const navigate = useNavigate();

  const isDowngrade = (plan: any) =>
    membershipRanking.findIndex(
      (value, index) => value === plan.payment.value
    ) <
      membershipRanking.findIndex(
        (value, index) => value === user?.membership
      ) &&
    new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) >
      new Date().setHours(0, 0, 0, 0);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        margin: "auto",
        padding: "40px 0",
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {plans.map((plan, index) => (
          <Grid
            item
            key={`${index}-dokgabi-paying`}
            sx={{ display: "flex", position: "relative" }}
          >
            {user &&
              membershipRanking.findIndex(
                (value, index) => value === plan.payment.value
              ) ===
                membershipRanking.findIndex(
                  (value, index) => value === user.membership
                ) && (
                <Chip
                  label="현재 사용중인 플랜"
                  sx={{
                    position: "absolute",
                    top: 8, // ✅ 더 위로 올리기
                    right: 16, // ✅ 오른쪽 정렬 보정
                    zIndex: 200, // ✅ 우선순위 높이기
                    background: "white",
                    color: "#4c24e3",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // ✅ 그림자 추가
                    border: "1px solid #4c24e3",
                    boxSizing: "border-box",
                  }}
                />
              )}
            <StyledCard>
              <CardContent sx={{ padding: 0, borderRadius: "12px" }}>
                <Box
                  sx={{
                    background: isDowngrade(plan) ? "#8f8f8f" : "#4c24e3",
                    padding: "20px",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color={isDowngrade(plan) ? "#ececec" : "white"}
                    sx={{ minHeight: 40 }}
                  >
                    {plan.payment.view_value}
                  </Typography>

                  {/* 설명 영역 */}
                  <Typography
                    variant="caption"
                    color={isDowngrade(plan) ? "#ececec" : "white"}
                    sx={{ marginY: 1, minHeight: 80, textAlign: "start" }}
                  >
                    {plan.description}
                  </Typography>

                  {/* 가격 + 기간 영역 */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color={isDowngrade(plan) ? "#ececec" : "white"}
                    sx={{ marginRight: 1, minHeight: 50 }}
                  >
                    ₩{(plan.price * plan.duration).toLocaleString()}
                  </Typography>

                  {/* 이용 개월 선택 */}

                  <Box
                    sx={{
                      color: "white",
                      width: "100%",
                      visibility: isDowngrade(plan) ? "hidden" : "visible",
                    }}
                  >
                    <MonthsSelector
                      label={
                        user
                          ? user.membership === "free"
                            ? "이용 기간"
                            : "이용 기간 추가"
                          : "이용 기간"
                      }
                      highlightColor="white"
                      // 이 플랜의 duration을 'month'로 주고
                      month={plan.duration}
                      // 해당 인덱스의 duration을 업데이트할 setter 전달
                      setMonth={handleSetMonth(index)}
                    />
                  </Box>

                  {/* 버튼 영역 */}
                  <Box sx={{ minHeight: 60, width: "100%" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: isDowngrade(plan)
                          ? "#ececec"
                          : "#ff00e4",
                        color: isDowngrade(plan) ? "#8f8f8f" : "white",
                        borderRadius: "8px",
                        marginTop: "16px",
                        padding: "10px 20px",
                        fontWeight: "bold",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor: isDowngrade(plan)
                            ? "#ececec"
                            : "#ff00e4",
                        },
                      }}
                      onClick={() =>
                        handlePurchaseDokgabiSingleButton(
                          plan.price,
                          plan.duration,
                          plan.payment.value,
                          { navigate }
                        )
                      }
                      fullWidth
                    >
                      {user
                        ? user.membership === "free" ||
                          new Date(user.membership_end_date) <= new Date()
                          ? "구매하기"
                          : // 현재 플랜보다 높은 플랜이면
                          membershipRanking.findIndex(
                              (value, index) => value === plan.payment.value
                            ) >
                            membershipRanking.findIndex(
                              (value, index) => value === user.membership
                            )
                          ? "플랜 업그레이드"
                          : membershipRanking.findIndex(
                              (value, index) => value === plan.payment.value
                            ) ===
                            membershipRanking.findIndex(
                              (value, index) => value === user.membership
                            )
                          ? "플랜 연장"
                          : "플랜 다운그레이드"
                        : "로그인 후 구매하기"}
                    </Button>
                  </Box>
                </Box>

                {/* '유용한 기능' 리스트 영역 */}
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "start",
                    padding: "15px 20px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    기능
                  </Typography>

                  <ul
                    style={{
                      listStyle: "none", // 원래 bullet(•)을 숨기기 위해
                      paddingLeft: 0,
                      marginTop: 8,
                    }}
                  >
                    {plan.features.map((feature, i) => (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "start",
                          marginBottom: "8px",
                        }}
                      >
                        {/* 체크 아이콘 */}
                        <Check
                          sx={{
                            color: "#B277F1", // 원하는 색상 (보라색)
                            fontSize: "1rem", // 아이콘 크기
                            marginRight: "8px",
                          }}
                        />

                        {/* 실제 텍스트 */}
                        <Typography fontSize="12px">{feature}</Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="outlined"
        sx={{
          marginTop: "50px",
          marginBottom: "50px",
          padding: "12px 24px",
          backgroundColor: "#4c24e3",
          color: "white",
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#4c24e3",
          },
        }}
        endIcon={openDetail ? <ExpandLess /> : <ExpandMore />}
        onClick={() => setOpenDetail((current) => !current)}
      >
        {openDetail ? "Hide all plan features" : "View all plan features"}
      </Button>

      {openDetail && <DokgabiPlanTable />}
    </Box>
  );
}
