import { EmailModalProps } from "../Atoms/EmailModalAtom";
import { ResponseStatus } from "../Enum/NetworkEnum";

interface PayLogProps {
  price: number;
  order_id: string;
  purchase_name: string;
  user_id: string;
  payment_key: string;
  payment_type: string;
  site_name: string;
}

export async function insertPayLog(
  access: string,
  payLog: PayLogProps
): Promise<boolean | string | number> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/insertPayLog`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify(payLog), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function updateUsage(
  access: string,
  id: string,
  asset: number,
  chat: number
): Promise<boolean | string | number> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateUsage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({ id: id, asset: asset, chat: chat }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function updatePlan(
  access: string,
  id: string,
  planName: string,
  month: number
): Promise<boolean | string | number> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updatePlan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({ id: id, plan_name: planName, month: month }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function updateCareerplannerPlan(
  access: string,
  id: string,
  planName: string,
  month: number
): Promise<boolean | string | number> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateCareerplannerPlan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({ id: id, plan_name: planName, month: month }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function updateCpOrganizerPlan(
  access: string,
  id: string,
  planName: string,
  people: number,
  groupName: string
): Promise<any | string | number> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateCpOrganizerPlan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          id: id,
          plan_name: planName,
          people: people,
          group_name: groupName,
        }), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

export async function sendEmailWhenQuestion(emailValue: EmailModalProps) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_USER_API_ADDRESS}/sendEmail/sendQuestion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailValue), // email 키값으로 이메일 주소를 전송
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    } else if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else {
      return ResponseStatus.ERROR;
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}
