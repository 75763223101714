import { useAtom } from "jotai";
import React from "react";
import {
  handlePricingMenuCloseAtom,
  handlePricingMenuOpenAtom,
} from "../../../../ViewModels/HeaderViewModel";
import { ListItemIcon, MenuItem } from "@mui/material";
import * as S from "./styled";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatIcon from "@mui/icons-material/Chat";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
} from "../../../../Atoms/PublicAtom";
import {
  handleCpPaymentClickAtom,
  handleDgPaymentClickAtom,
  handleTlPaymentClickAtom,
} from "../../../../ViewModels/PaymentViewModel";
import { useNavigate } from "react-router-dom";
import { Diversity3 } from "@mui/icons-material";

export function PaymentMenu() {
  const [dokgabiUser] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);

  const [pricingOpen] = useAtom(handlePricingMenuOpenAtom);
  const [pricingAnchorEl, pricingHandleClose] = useAtom(
    handlePricingMenuCloseAtom
  );

  const [, handleCpPaymentClick] = useAtom(handleCpPaymentClickAtom);
  const [, handleTlPaymentClick] = useAtom(handleTlPaymentClickAtom);
  const [, handleDgPaymentClick] = useAtom(handleDgPaymentClickAtom);

  const navigate = useNavigate();

  return (
    <S.CustomMenu
      anchorEl={pricingAnchorEl}
      id="payment-menu"
      open={pricingOpen}
      onClose={pricingHandleClose}
      onClick={pricingHandleClose}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <MenuItem onClick={() => handleCpPaymentClick({ navigate })}>
        <ListItemIcon>
          <CalendarMonthIcon fontSize="small" />
        </ListItemIcon>
        커리어플래너 요금제
      </MenuItem>
      <MenuItem onClick={() => handleTlPaymentClick({ navigate })}>
        <ListItemIcon>
          <Diversity3 fontSize="small" />
        </ListItemIcon>
        팀플 요금제
      </MenuItem>
      <MenuItem onClick={() => handleDgPaymentClick({ navigate })}>
        <ListItemIcon>
          <ChatIcon fontSize="small" />
        </ListItemIcon>
        챗봇빌더 요금제
      </MenuItem>
    </S.CustomMenu>
  );
}
