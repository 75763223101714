import { atom } from "jotai";
import { paymentTypeAtom } from "../Atoms/PublicAtom";
import { currentPayServiceAtom } from "../Atoms/PlanTableAtom";
import { ServiceType } from "../Enum/BillingTypeEnum";

export const handleCpPaymentClickAtom = atom(null, (get, set, { navigate }) => {
  set(currentPayServiceAtom, ServiceType.CAREER_PALNNER);
  navigate("/");
});

export const handleTlPaymentClickAtom = atom(null, (get, set, { navigate }) => {
  set(currentPayServiceAtom, ServiceType.TEAMPL);
  navigate("/");
});

export const handleDgPaymentClickAtom = atom(null, (get, set, { navigate }) => {
  set(currentPayServiceAtom, ServiceType.DOKGABI);
  navigate("/");
});
