import { Box, Typography, Paper, Button } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
  teamplUserAtom,
} from "../Atoms/PublicAtom";
import { nanoid } from "nanoid";

const price = [
  { plan: "lite", price: 10000 },
  { plan: "essential", price: 150000 },
  { plan: "pro", price: 600000 },
  { plan: "premium", price: 2000000 },
  { plan: "basic", price: 10000 },
  { plan: "standard", price: 29800 },
  { plan: "professional", price: 150000 },
];

export function TlUpgrade() {
  const [user] = useAtom(teamplUserAtom);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [totalPrice, setTotalPrice] = useState(0);
  const [remainingMonths, setRemainingMonths] = useState(0);
  const [remainingDays, setRemainingDays] = useState(0);
  const [diffUpgradePlanPrice, setDiffUpgradePlanPrice] = useState(0);
  const [upgradePlanPrice, setUpgradePlanPrice] = useState(0);
  const [currentPlanPrice, setCurrentPlanPrice] = useState(0);
  const [leftoverCost, setLeftoverCost] = useState(0);
  const [additionalCost, setAdditionalCost] = useState(0);

  const initialState = {
    month: Number(queryParams.get("month")) || 0,
    plan: queryParams.get("plan") || null,
  };

  useEffect(() => {
    if (user) {
      const currentPrice =
        price.find((value) => value.plan === user.membership)?.price ?? 0;
      const upgradePrice =
        price.find((value) => value.plan === initialState.plan)?.price ?? 0;

      setCurrentPlanPrice(currentPrice);
      setUpgradePlanPrice(upgradePrice);

      const diffPrice = upgradePrice - currentPrice;
      setDiffUpgradePlanPrice(diffPrice);

      const membershipEndDate = new Date(user.membership_end_date);
      const todayDate = new Date();

      // 남은 총 일수 계산
      const totalDaysLeft = Math.floor(
        (membershipEndDate.getTime() - todayDate.getTime()) /
          (1000 * 60 * 60 * 24)
      );

      // 남은 달 수와 남은 일 수 계산
      const monthsLeft = Math.floor(totalDaysLeft / 30);
      const daysLeft = totalDaysLeft % 30;

      setRemainingMonths(monthsLeft);
      setRemainingDays(daysLeft);

      // 남은 기간의 업그레이드 비용 (소수점 버림)
      const leftoverPlanCost = Math.floor(
        monthsLeft * diffPrice + Math.floor((diffPrice / 30) * daysLeft)
      );
      setLeftoverCost(leftoverPlanCost);

      // 추가 이용 기간의 비용 (소수점 버림)
      const additionalPlanCost = Math.floor(initialState.month * upgradePrice);
      setAdditionalCost(additionalPlanCost);

      // 총 비용 계산 (소수점 버림)
      setTotalPrice(leftoverPlanCost + additionalPlanCost);
    }
  }, [user]);

  const navigate = useNavigate();

  if (user === null || initialState.plan === null)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 56px)",
          background: "#f5f5f5",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            marginTop: "50px",
            marginBottom: "50px",
            padding: "12px 24px",
            backgroundColor: "rgb(9,191,253)",
            color: "white",
            fontWeight: "bold",
            textTransform: "none",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "rgb(9,191,253)",
              border: "none",
            },
          }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_TEAMPL_LOGIN_ADDRESS)
          }
        >
          로그인 후에 이용해주세요
        </Button>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 56px)",
        background: "#f5f5f5",
      }}
    >
      <Paper
        sx={{
          maxWidth: "400px",
          width: "100%",
          padding: "24px",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          background: "white",
          textAlign: "center",
          border: "1px solid #8f8f8f",
        }}
      >
        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          현재 사용 중인 플랜
        </Typography>
        <Typography fontSize="12px">
          🎫 {user.membership} (월 {currentPlanPrice.toLocaleString()}원)
        </Typography>
        <Typography fontSize="12px" sx={{ mb: 2 }}>
          📅 {new Date(user.membership_end_date).toLocaleDateString()}까지 이용
          가능
        </Typography>

        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          업그레이드할 플랜
        </Typography>
        <Typography fontSize="12px">
          🚀 {initialState.plan} (월 {upgradePlanPrice.toLocaleString()}원)
        </Typography>

        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mt: 3, mb: 1 }}
        >
          업그레이드 비용 계산
        </Typography>
        <Typography fontSize="12px">
          📌 남은 기간: {remainingMonths}개월 {remainingDays}일
        </Typography>
        <Typography fontSize="12px">
          💰 업그레이드된 플랜과 현재 플랜의 차액:{" "}
          {diffUpgradePlanPrice.toLocaleString()}원
        </Typography>

        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mt: 3, mb: 1 }}
        >
          💵 결제 금액 상세
        </Typography>
        <Typography fontSize="12px">
          - 남은 기간 업그레이드 비용: {leftoverCost.toLocaleString()}원
        </Typography>
        <Typography fontSize="12px">
          - 추가 이용 기간({initialState.month}개월) 비용:{" "}
          {additionalCost.toLocaleString()}원
        </Typography>

        <Typography
          variant="h5"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mt: 3, color: "#4c24e3" }}
        >
          💳 총 결제 금액: {totalPrice.toLocaleString()}원
        </Typography>
        <Button
          sx={{ width: "100%", padding: "10px", marginTop: "30px" }}
          variant="contained"
          onClick={() =>
            navigate(
              `/checkout?price=${totalPrice}&orderId=${nanoid()}&orderName=플랜 업그레이드 ${
                initialState.plan
              } ${initialState.month}개월&customerName=${
                user.id
              }&customerEmail=${
                user.id
              }&siteName=teampl&payType=single&changeType=upgrade&currentPlan=${
                user.membership
              }&newPlan=${initialState.plan}&month=${initialState.month}`
            )
          }
        >
          플랜 업그레이드
        </Button>
      </Paper>
    </Box>
  );
}
