export enum BillingType {
  SUBSCRIPTION = 0,
  SINGLE = 1,
}

export enum ServiceType {
  CAREER_PALNNER = 0,
  TEAMPL = 1,
  DOKGABI = 2,
}
