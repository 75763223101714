import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { PlanTable } from "./Pages/PlanTable";
import { SuccessPage } from "./Pages/Success";
import { FailPage } from "./Pages/Fail";
import { ExtraCharge } from "./Pages/ExtraCharge";
import { Refund } from "./Pages/Refund";
import { Upgrade } from "./Pages/Upgrade";
import Checkout from "./Pages/Checkout";
import { RefundPolicy } from "./Pages/RefundPolicy";
import { CpUpgrade } from "./Pages/CpUpgrade";
import { TlUpgrade } from "./Pages/TlUpgrade";
const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route path="" element={<PlanTable />} />
        <Route path="refund" element={<Refund />} />
        <Route path="upgrade" element={<Upgrade />} />
        <Route path="cp-upgrade" element={<CpUpgrade />} />
        <Route path="tl-upgrade" element={<TlUpgrade />} />
        <Route path="extra" element={<ExtraCharge />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="fail" element={<FailPage />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
