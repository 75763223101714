import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { refundListAtom } from "../Atoms/RefundAtom";

export function RefundPolicy() {
  const [refundList] = useAtom(refundListAtom);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        minHeight: "calc(100vh - 56px)",
        boxSizing: "border-box",
        padding: "40px 20%",
      }}
      direction={"column"}
    >
      <Stack direction={"row"}>
        <Typography variant="body1" sx={{ color: "#8f8f8f" }}>
          홈
        </Typography>
        <KeyboardArrowRight sx={{ color: "#8f8f8f" }} />
        <Typography variant="body1" sx={{ color: "#8f8f8f" }}>
          환불 규정
        </Typography>
      </Stack>
      <Divider />
      <div style={{ height: "20px" }}></div>
      <ul>
        {refundList.map((refund, index) => (
          <li>
            {refund.text}
            {refund.children.length > 0 && (
              <ul>
                {refund.children.map((child, index) => (
                  <li>{child}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li>
          회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가 안내하는 정책
          및 방법에 따라 진행됩니다.
        </li>
      </ul>
    </Stack>
  );
}
