import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";

import {
  loadPaymentWidget,
  PaymentWidgetInstance,
} from "@tosspayments/payment-widget-sdk";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { dokgabiUserAtom } from "../Atoms/PublicAtom";
import { getCookie } from "../Utils/CookieUtil";
import * as S from "../Components/Checkout/styled";

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const customerKey = nanoid();

const Checkout: React.FC = () => {
  const [user] = useAtom(dokgabiUserAtom);

  const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey);

  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] =
    useState(false);

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance["renderPaymentMethods"]
  > | null>(null);

  const navigate = useNavigate();

  const location = useLocation();

  // ✅ queryString에서 결제 정보 가져오기
  const queryParams = new URLSearchParams(location.search);
  const initialState = {
    state: queryParams.get("state") === "true",
    price: Number(queryParams.get("price")) || 0,
    asset: Number(queryParams.get("asset")) || 0,
    chat: Number(queryParams.get("chat")) || 0,
    planName: queryParams.get("planName") || "",
    orderName: queryParams.get("orderName") || "",
    orderId: queryParams.get("orderId") || "",
    customerName: queryParams.get("customerName") || "",
    customerEmail: queryParams.get("customerEmail") || "",
    siteName: queryParams.get("siteName") || "",
    payType: queryParams.get("payType") || "",
    changeType: queryParams.get("changeType") || "",
    currentPlan: queryParams.get("currentPlan") || "",
    newPlan: queryParams.get("newPlan") || "",
    month: queryParams.get("month") || "",
  };

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      { value: initialState.price },
      { variantKey: "DEFAULT" }
    );

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    //  ------  결제 UI 렌더링 완료 이벤트 ------
    paymentMethodsWidget.on("ready", () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      isPaymentMethodsWidgetReady(true);
    });

    return () => {
      if (paymentMethodsWidgetRef.current) {
        paymentMethodsWidgetRef.current = null;
      }

      isPaymentMethodsWidgetReady(false);
    };
  }, [paymentWidget]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(initialState.price);
  }, [initialState.price]);

  const whenPurcahseButtonClick = async () => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const refreshCpCookie = getCookie(
      process.env.REACT_APP_CP_REFRESH_COOKIE_ID
    );
    const accessCpCookie = getCookie(process.env.REACT_APP_CP_ACCESS_COOKIE_ID);

    if (
      initialState.siteName === "dokgabi" &&
      (!refreshCookie || !accessCookie) // 둘 중 하나라도 null이면 return
    )
      return;

    if (
      initialState.siteName === "careerplanner" &&
      (!refreshCpCookie || !accessCpCookie) // 둘 중 하나라도 null이면 return
    )
      return;

    try {
      const response = await paymentWidget?.requestPayment({
        orderId: initialState.orderId,
        orderName: initialState.orderName,
        successUrl: `${window.location.origin}/success?payType=${initialState.payType}&siteName=${initialState.siteName}&changeType=${initialState.changeType}&currentPlan=${initialState.currentPlan}&newPlan=${initialState.newPlan}&month=${initialState.month}&asset=${initialState.asset}&chat=${initialState.chat}`,
        failUrl: `${window.location.origin}/fail`,
        customerName: initialState.customerName,
        customerEmail: initialState.customerEmail,
      });
    } catch (err) {
      navigate("/");
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <S.PayBox>
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            결제 정보
          </Typography>
          <Divider sx={{ marginBottom: "16px" }} />

          {/* 상품명 */}
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="12px"
          >
            <Typography sx={{ fontWeight: "bold", color: "#555" }}>
              상품명
            </Typography>
            <Typography sx={{ color: "#000" }}>
              {initialState.orderName}
            </Typography>
          </Box>

          {/* 상품가격 */}
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: "bold", color: "#555" }}>
              상품가격
            </Typography>
            <Typography sx={{ color: "#000" }}>
              {initialState.price.toLocaleString()}원
            </Typography>
          </Box>
        </Box>

        {/* Toss Payments 위젯 */}
        <div id="payment-widget" />

        <div id="agreement" />

        {/* 결제 및 취소 버튼 */}
        <S.payButton
          variant="contained"
          disabled={!paymentMethodsWidgetReady}
          onClick={whenPurcahseButtonClick}
        >
          결제
        </S.payButton>
        <S.closeButton
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          취소
        </S.closeButton>
      </S.PayBox>
    </Box>
  );
};

export default Checkout;

function usePaymentWidget(clientKey: string, customerKey: string) {
  return useQuery({
    queryKey: ["payment-widget", clientKey, customerKey],
    queryFn: () => {
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
}
