import { atom } from "jotai";
import { BillingType, ServiceType } from "../Enum/BillingTypeEnum";
import { usePayOptions } from "../Initialize/PayOptions";

interface FeatureProps {
  feature: string;
  tooltip: string;
  value?: number;
}

interface PlanProps {
  planName: string;
  price: number;
  desc: string;
  featureList: FeatureProps[];
}

export const payTypeAtom = atom(BillingType.SINGLE);

export const pageListAtom = atom([
  {
    value: BillingType.SINGLE,
    view_value: "단건 결제",
  },
  {
    value: BillingType.SUBSCRIPTION,
    view_value: "정기 결제",
  },
]);

export const handleEmailModalAtom = atom(false);

export const PlansAtom = atom<PlanProps[]>([
  {
    planName: "lite",
    price: 10000,
    desc: "독갑이를 처음 사용하는 유저에게 추천",
    featureList: [
      {
        feature: "1명의 관리자",
        tooltip: "구입을한 한명의 유저만 자신의 챗봇을 관리할 수 있습니다.",
      },
      {
        feature: "1개의 챗봇",
        tooltip: "최대 한개의 챗봇을 생헝할 수 있습니다.",
      },
      {
        feature: "100개 메시지 크레딧",
        tooltip: "100번 이후 발생되는 대화에서는 추과 과금이 발생합니다.",
        value: 100,
      },
    ],
  },
  {
    planName: "essential",
    price: 150000,
    desc: "독갑이 사용에 익숙해지고 자신만의 챗봇으로 서비스를 운영하고 싶은 유저에게 추천",
    featureList: [
      {
        feature: "1명의 관리자",
        tooltip: "구입을한 한명의 유저만 자신의 챗봇을 관리할 수 있습니다.",
      },
      {
        feature: "2개의 커스텀 챗봇 + 1개의 템플릿 챗봇",
        tooltip: "최대 세개의 챗봇을 생헝할 수 있습니다.",
      },
      {
        feature: "총 3개의 무료 Asset 세트",
        tooltip: "이음이 가지고 있는 고 품질의 Asset 세트 3개 제공",
      },
      {
        feature: "총 31개의 무료 캐릭터 말투",
        tooltip: "31개의 독특한 말투를 가진 챗봇 캐릭터 말투 제공",
      },
      {
        feature: "10,000개의 커스텀 Asset 데이터",
        tooltip:
          "800토큰 씩 가지는 총 10,000개의 커스텀 Asset 데이터를 생성할 수 없습니다.",
      },
      {
        feature: "3,000개 메시지 크레딧",
        tooltip: "3,000번 이후 발생되는 대화에서는 추과 과금이 발생합니다.",
        value: 3000,
      },
      {
        feature: "Optional: 온보딩서비스",
        tooltip:
          "온보딩 서비스를 통해, 독갑이 사용에 어려움이 있을 경우 저희가 도움을 드릴 수 있습니다.",
      },
    ],
  },
  {
    planName: "pro",
    price: 600000,
    desc: "좀 더 많은 데이터를 활용해서 챗봇의 정확도를 올리고 싶은 유저에게 추천",
    featureList: [
      {
        feature: "3명의 관리자",
        tooltip: "2명의 유저를 추가 초대하여 같이 챗봇을 관리할 수 있습니다.",
      },
      {
        feature: "2개의 커스텀 챗봇 + 1개의 템플릿 챗봇",
        tooltip: "최대 세개의 챗봇을 생헝할 수 있습니다.",
      },
      {
        feature: "총 31개의 무료 Asset 세트",
        tooltip: "이음이 가지고 있는 고 품질의 Asset 세트 12개 제공",
      },
      {
        feature: "총 31개의 무료 캐릭터 말투",
        tooltip: "31개의 독특한 말투를 가진 챗봇 캐릭터 말투 제공",
      },
      {
        feature: "40,000개의 커스텀 Asset 데이터",
        tooltip:
          "800토큰 씩 가지는 총 40,000개의 커스텀 Asset 데이터를 생성할 수 없습니다.",
      },
      {
        feature: "15,000개 메시지 크레딧",
        tooltip: "15,000번 이후 발생되는 대화에서는 추과 과금이 발생합니다.",
        value: 15000,
      },
      {
        feature: "Optional: 온보딩서비스",
        tooltip:
          "온보딩 서비스를 통해, 독갑이 사용에 어려움이 있을 경우 저희가 도움을 드릴 수 있습니다.",
      },
      {
        feature: "Optional: Asset 재학습",
        tooltip:
          "Asset 재학습을 통해 최산화된 Asset을 계속해서 사용하실 수 있습니다.",
      },
    ],
  },
  {
    planName: "premium",
    price: 2000000,
    desc: "대용량의 데이터를 활용하여 챗봇을 만들고 싶은 유저에게 추천",
    featureList: [
      {
        feature: "5명의 관리자",
        tooltip: "4명의 유저를 추가 초대하여 같이 챗봇을 관리할 수 있습니다.",
      },
      {
        feature: "4개의 커스텀 챗봇 + 1개의 템플릿 챗봇",
        tooltip: "최대 다섯 개의 챗봇을 생헝할 수 있습니다.",
      },
      {
        feature: "총 56개의 무료 Asset 세트",
        tooltip: "이음이 가지고 있는 고 품질의 Asset 세트 12개 제공",
      },
      {
        feature: "총 31개의 무료 캐릭터 말투",
        tooltip: "31개의 독특한 말투를 가진 챗봇 캐릭터 말투 제공",
      },
      {
        feature: "160,000개의 커스텀 Asset 데이터",
        tooltip:
          "800토큰 씩 가지는 총 160,000개의 커스텀 Asset 데이터를 생성할 수 없습니다.",
      },
      {
        feature: "50,000개 메시지 크레딧",
        tooltip: "50,000번 이후 발생되는 대화에서는 추과 과금이 발생합니다.",
        value: 50000,
      },
      {
        feature: "Optional: 온보딩서비스",
        tooltip:
          "온보딩 서비스를 통해, 독갑이 사용에 어려움이 있을 경우 저희가 도움을 드릴 수 있습니다.",
      },
      {
        feature: "Optional: Asset 재학습",
        tooltip:
          "Asset 재학습을 통해 최산화된 Asset을 계속해서 사용하실 수 있습니다.",
      },
    ],
  },
]);

interface SingleOptionProps {
  planName: string;
  month: number;
}

export const singleOptionAtom = atom<SingleOptionProps[]>([]);

export const careerplannerPeopleAtom = atom<number | null>(null);

export const organizationNameAtom = atom<string | null>(null);

// 현재 버전에서 쓰는 거 --------------------------------------------------------------------
export const currentPayServiceAtom = atom(ServiceType.CAREER_PALNNER);

interface DokgabiPaymentSettingProps {
  service: ServiceType;
  payment: {
    view_value: string;
    value: string;
  };
  duration: number;
  price: number;
  description: string;
  features: string[];
}

export const dokgabiPaymentSettingListAtom = atom<DokgabiPaymentSettingProps[]>(
  usePayOptions().initializeDokgabiPaymentSetting
);

interface CareerplannerPaymentSettingProps {
  service: ServiceType;
  payment: {
    view_value: string;
    value: string;
  };
  duration: number;
  price: number;
  // people: number | null;
  description: string;
  features: string[];
}

export const careerPlannerpaymentSettingListAtom = atom<
  CareerplannerPaymentSettingProps[]
>(usePayOptions().initializeCareerplannerPaymentSetting);

interface FeatureItem {
  func: string;
  label: string;
}

interface TeamplPaymentSettingProps {
  service: ServiceType;
  payment: {
    view_value: string;
    value: string;
  };
  duration: number;
  price: number;
  // people: number | null;
  description: string;
  features: any[];
}

export const teamplpaymentSettingListAtom = atom<TeamplPaymentSettingProps[]>(
  usePayOptions().initializeTeamplPaymentSetting
);
