import { Box, Button } from "@mui/material";
import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { tossPaymentsModalAtom } from "../Atoms/RootAtom";
import {
  handleCareerplannerSinglePaymentAtom,
  handleDokgabiExtraAtom,
  handleDokgabiSinglePaymentAtom,
} from "../ViewModels/TossPaymentsModalViewModel";

export function SuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [responseData, setResponseData] = useState(null);

  const [, handleDokgabiExtraPayment] = useAtom(handleDokgabiExtraAtom);

  const [, handleDokgabiSinglePayment] = useAtom(
    handleDokgabiSinglePaymentAtom
  );

  const [, handleCareerplannerSinglePayment] = useAtom(
    handleCareerplannerSinglePaymentAtom
  );

  const location = useLocation();

  // ✅ queryString에서 결제 정보 가져오기
  const queryParams = new URLSearchParams(location.search);
  const initialState = {
    payType: queryParams.get("payType") || "",
    siteName: queryParams.get("siteName") || "",
    changeType: queryParams.get("changeType") || "",
    currentPlan: queryParams.get("currentPlan") || "",
    newPlan: queryParams.get("newPlan") || "",
    month: Number(queryParams.get("month")) || 0,
    asset: Number(queryParams.get("asset")) || 0,
    chat: Number(queryParams.get("chat")) || 0,
    people: Number(queryParams.get("people")) || 0,
    organizationName: queryParams.get("organizationName") || "",
  };

  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };

    // TODO: 개발자센터에 로그인해서 내 결제위젯 연동 키 > 시크릿 키를 입력하세요. 시크릿 키는 외부에 공개되면 안돼요.
    // @docs https://docs.tosspayments.com/reference/using-api/api-keys
    const secretKey = process.env.REACT_APP_TOSS_SECRET_KEY;

    // 토스페이먼츠 API는 시크릿 키를 사용자 ID로 사용하고, 비밀번호는 사용하지 않습니다.
    // 비밀번호가 없다는 것을 알리기 위해 시크릿 키 뒤에 콜론을 추가합니다.
    // @docs https://docs.tosspayments.com/reference/using-api/authorization#%EC%9D%B8%EC%A6%9D
    const encryptedSecretKey = `Basic ${btoa(secretKey + ":")}`;

    async function confirm() {
      const response = await fetch(
        "https://api.tosspayments.com/v1/payments/confirm",
        {
          method: "POST",
          headers: {
            Authorization: encryptedSecretKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const json = await response.json();

      if (!response.ok) {
        // TODO: 구매 실패 비즈니스 로직 구현
        navigate(`/fail?code=${json.code}&message=${json.message}`);
        return;
      }

      // TODO: 결제 성공 비즈니스 로직을 구현하세요.
      console.log("success-json", json);

      if (
        initialState.siteName === "dokgabi" &&
        initialState.payType === "single"
      ) {
        handleDokgabiSinglePayment(initialState, json);
      } else if (
        initialState.siteName === "dokgabi" &&
        initialState.payType === "extra"
      ) {
        handleDokgabiExtraPayment(initialState, json);
      } else if (
        initialState.siteName === "careerplanner" &&
        initialState.payType === "single"
      ) {
        handleCareerplannerSinglePayment(initialState, json);
      }

      return json;
    }
    confirm().then((data) => {
      setResponseData(data);
    });
  }, [searchParams]);

  return (
    <Box
      className="box_section"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <div className="" style={{ width: "600px" }}> */}
      <img
        width="100px"
        src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png"
      />
      <h2>결제를 완료했어요</h2>
      <div className="p-grid typography--p" style={{ marginTop: "50px" }}>
        <div className="p-grid-col text--left">
          <b>결제금액</b>
        </div>
        <div className="p-grid-col text--right" id="amount">
          {`${Number(searchParams.get("amount")).toLocaleString()}원`}
        </div>
      </div>
      <div className="p-grid typography--p" style={{ marginTop: "10px" }}>
        <div className="p-grid-col text--left">
          <b>주문번호</b>
        </div>
        <div className="p-grid-col text--right" id="orderId">
          {`${searchParams.get("orderId")}`}
        </div>
      </div>
      <Button
        variant={"contained"}
        onClick={() => navigate("/")}
        sx={{
          marginTop: "20px",
          background: process.env.REACT_APP_MAIN_COLOR,
          "&:hover": {
            background: process.env.REACT_APP_MAIN_COLOR,
          },
        }}
      >
        홈으로
      </Button>
      {/* <div className="p-grid typography--p" style={{ marginTop: "10px" }}>
          <div className="p-grid-col text--left">
            <b>paymentKey</b>
          </div>
          <div
            className="p-grid-col text--right"
            id="paymentKey"
            style={{ whiteSpace: "initial", width: "250px" }}
          >
            {`${searchParams.get("paymentKey")}`}
          </div>
        </div>
        <div className="p-grid-col">
          <Link to="https://docs.tosspayments.com/guides/payment-widget/integration">
            <button className="button p-grid-col5">연동 문서</button>
          </Link>
          <Link to="https://discord.gg/A4fRFXQhRu">
            <button
              className="button p-grid-col5"
              style={{ backgroundColor: "#e8f3ff", color: "#1b64da" }}
            >
              실시간 문의
            </button>
          </Link>
        </div>
      </div>
      <div
        className="box_section"
        style={{ width: "600px", textAlign: "left" }}
      >
        <b>Response Data :</b>
        <div id="response" style={{ whiteSpace: "initial" }}>
          {responseData && <pre>{JSON.stringify(responseData, null, 4)}</pre>}
        </div>
      </div> */}
      {/* </div> */}
    </Box>
  );
}
