import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

// 플랜명과 가격
const PLAN_NAMES = ["Basic", "Standard", "Professional"];
const PLAN_PRICES = ["10000원/월", "29800원/월", "150000원/월"];

// Y를 체크 아이콘으로 변환하는 함수
const formatValue = (value: string) => {
  return value === "Y" ? (
    <CheckCircleIcon sx={{ color: "green" }} />
  ) : (
    value || "-"
  );
};

// 데이터 섹션
const planData = [
  {
    category: "시그니처카드",
    items: [{ label: "발행수", values: ["1개", "3개", "5개"] }],
    color: "#DDEBF7",
  },
  {
    category: "챗봇",
    items: [
      { label: "대화수", values: ["100개", "300개", "1500개"] },
      { label: "채팅방 개설", values: ["1개", "3개", "5개"] },
      { label: "대화내용 검색", values: ["Y", "Y", "Y"] },
      { label: "AI 진로컨설턴트(엘리스)", values: ["Y", "Y", "Y"] },
      { label: "직업 추천", values: ["Y", "Y", "Y"] },
      { label: "할일 추천", values: ["-", "Y", "Y"] },
      { label: "AI 멘토", values: ["-", "Y", "Y"] },
    ],
    color: "#D8EAD3",
  },
  {
    category: "플랜",
    items: [
      { label: "커리어플랜 개설", values: ["Y", "Y", "Y"] },
      { label: "크루 공유", values: ["Y", "Y", "Y"] },
      { label: "루틴 개설", values: ["Y", "Y", "Y"] },
      { label: "할일 관리", values: ["Y", "Y", "Y"] },
      { label: "플랜 피드백", values: ["-", "Y", "Y"] },
      { label: "연관 직업 추천", values: ["-", "Y", "Y"] },
    ],
    color: "#C9DAF8",
  },
  {
    category: "크루",
    items: [
      { label: "피드 관리", values: ["Y", "Y", "Y"] },
      { label: "방과후학교 개설", values: ["Y", "Y", "Y"] },
      { label: "크루 추천", values: ["-", "Y", "Y"] },
      { label: "CO-ESSAY", values: ["-", "Y", "Y"] },
      { label: "설문조사", values: ["-", "-", "Y"] },
      { label: "투표", values: ["-", "-", "Y"] },
    ],
    color: "#F9CB9C",
  },
  {
    category: "대시보드",
    items: [
      { label: "진로 진학 탐색", values: ["Y", "Y", "Y"] },
      { label: "추천 직업", values: ["Y", "Y", "Y"] },
      { label: "추천 콘텐츠", values: ["Y", "Y", "Y"] },
      { label: "전체 일정 캘린더", values: ["Y", "Y", "Y"] },
      { label: "학생별 커리어", values: ["Y", "Y", "Y"] },
      { label: "리서치 통계", values: ["-", "-", "Y"] },
    ],
    color: "#F4CCCC",
  },
  {
    category: "종량제",
    items: [
      { label: "대화수", values: ["10원/대화", "10원/대화", "10원/대화"] },
    ],
    color: "#FCE5CD",
  },
];

// 공통 열 그룹 설정
function FourColumnGroup() {
  return (
    <colgroup>
      <col style={{ width: "30%" }} />
      <col style={{ width: "23.3%" }} />
      <col style={{ width: "23.3%" }} />
      <col style={{ width: "23.3%" }} />
    </colgroup>
  );
}

// 플랜 테이블 컴포넌트
export function CareerplannerPlanTable() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [activePlan, setActivePlan] = useState(0);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1032px",
        margin: "0 auto",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
        boxSizing: "border-box",
        overflowX: "auto", // ✅ 모바일에서 가로 스크롤 가능하게 설정
      }}
    >
      {isMobile ? (
        <Box
          textAlign="center"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <IconButton
            onClick={() => setActivePlan((prev) => Math.max(prev - 1, 0))}
            disabled={activePlan === 0}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" display="inline">
            {PLAN_NAMES[activePlan]}
          </Typography>
          <IconButton
            onClick={() =>
              setActivePlan((prev) => Math.min(prev + 1, PLAN_NAMES.length - 1))
            }
            disabled={activePlan === PLAN_NAMES.length - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
      ) : (
        <>
          {/* 플랜명과 가격 */}
          <Table
            sx={{
              tableLayout: "fixed",
              minWidth: isMobile ? "600px" : "100%", // ✅ 모바일에서는 최소 가로폭 설정
            }}
          >
            <FourColumnGroup />
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: isMobile ? "14px" : "16px", // ✅ 모바일에서는 폰트 크기 축소
                  }}
                >
                  플랜명
                </TableCell>
                {PLAN_NAMES.map((planName, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    {planName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  가격
                </TableCell>
                {PLAN_PRICES.map((price, idx) => (
                  <TableCell key={idx} sx={{ textAlign: "center" }}>
                    {price}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>

          {/* 섹션별 아코디언 */}
          {planData.map((section, idx) => (
            <Accordion key={idx} defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    width: "100%",
                    padding: "5px 10px",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  {section.category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table sx={{ tableLayout: "fixed", minWidth: "600px" }}>
                  <FourColumnGroup />
                  <TableBody>
                    {section.items.map((row, rowIdx) => (
                      <TableRow
                        key={rowIdx}
                        sx={{
                          backgroundColor:
                            rowIdx % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        }}
                      >
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {row.label}
                        </TableCell>
                        {row.values.map((val, valIdx) => (
                          <TableCell key={valIdx} sx={{ textAlign: "center" }}>
                            {formatValue(val)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      {/* 📌 모바일: 선택된 플랜의 상세 정보 */}
      {isMobile && (
        <Box mt={2}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {PLAN_NAMES[activePlan]} 플랜 상세 정보
          </Typography>
          <List>
            {planData.map((section, idx) => (
              <Box key={idx} mt={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "2px solid #ccc",
                    pb: 1,
                  }}
                >
                  {section.category}
                </Typography>
                {section.items.map((row, rowIdx) => (
                  <ListItem
                    key={rowIdx}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ListItemText primary={row.label} />
                    <Typography>
                      {formatValue(row.values[activePlan])}
                    </Typography>
                  </ListItem>
                ))}
              </Box>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}
