import React from "react";
import { useAtom } from "jotai";
import { Avatar, Button, Icon, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as S from "./styled";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
  teamplUserAtom,
} from "../../../Atoms/PublicAtom";
import { ProfileMenu } from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";

import {
  handleNoticeWhenServiceIsInDevelopAtom,
  handlePricingMenuOpenAtom,
  handleProfileMenuOpenAtom,
  setStateDrawerAtom,
} from "../../../ViewModels/HeaderViewModel";
import { PaymentMenu } from "./PaymentMenu";
import { currentPayServiceAtom } from "../../../Atoms/PlanTableAtom";
import { ServiceType } from "../../../Enum/BillingTypeEnum";

export function Header() {
  const [user] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);
  const [tlUser] = useAtom(teamplUserAtom);
  const [service] = useAtom(currentPayServiceAtom);
  const [profileOpen, profileHandleClick] = useAtom(handleProfileMenuOpenAtom);
  const [pricingOpen, pricingHandleClick] = useAtom(handlePricingMenuOpenAtom);

  const [, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [, handleNoticeWhenServiceIsInDevelop] = useAtom(
    handleNoticeWhenServiceIsInDevelopAtom
  );

  const navigate = useNavigate();

  return (
    <S.HeaderContainer>
      {/* 모바일 환경일 시 */}
      <S.HeaderMobileContainer>
        <IconButton onClick={(e) => toggleDrawer(true, e)}>
          <MenuIcon />
        </IconButton>
      </S.HeaderMobileContainer>

      {/* 모바일 환경 아닐 시 */}
      <S.HeaderInnerContainer>
        <S.HeaderInnerInnerContainer gap="0">
          {/* <IconButton onClick={(e) => toggleDrawer(true, e)}>
            <MenuIcon />
          </IconButton> */}
          <IconButton onClick={() => navigate("/")} sx={{ margin: "0 16px" }}>
            <Avatar
              alt="Remy Sharp"
              src={
                service === ServiceType.CAREER_PALNNER
                  ? "/careerplanner.png"
                  : service === ServiceType.TEAMPL
                  ? "/teampl.png"
                  : "/favicon.ico"
              }
            />
          </IconButton>
          <Button
            onClick={pricingHandleClick}
            size="small"
            aria-controls={pricingOpen ? "payment-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={pricingOpen ? "true" : undefined}
            // onClick={() => navigate("/")}
            // aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            요금제
          </Button>
          <Button
            onClick={handleNoticeWhenServiceIsInDevelop}
            aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            튜토리얼
          </Button>
          <Button
            onClick={() =>
              (window.location.href = cpUser
                ? process.env.REACT_APP_CP_DASHBOARD_ADDRESS
                : process.env.REACT_APP_CP_LOGIN_ADDRESS)
            }
            size="small"
            aria-controls={pricingOpen ? "payment-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={pricingOpen ? "true" : undefined}
            // onClick={() => navigate("/")}
            // aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            커리어플래너
          </Button>
          <Button
            onClick={() =>
              (window.location.href = tlUser
                ? process.env.REACT_APP_TEAMPL_DASHBOARD_ADDRESS
                : process.env.REACT_APP_TEAMPL_LOGIN_ADDRESS)
            }
            size="small"
            aria-controls={pricingOpen ? "payment-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={pricingOpen ? "true" : undefined}
            // onClick={() => navigate("/")}
            // aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            팀플
          </Button>
          <Button
            onClick={() =>
              (window.location.href = cpUser
                ? process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS
                : process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS)
            }
            size="small"
            aria-controls={pricingOpen ? "payment-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={pricingOpen ? "true" : undefined}
            // onClick={() => navigate("/")}
            // aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            독갑이™
          </Button>
        </S.HeaderInnerInnerContainer>

        <S.HeaderInnerInnerContainer gap="0px">
          {user || cpUser ? (
            <>
              <IconButton
                onClick={profileHandleClick} //   <IconButton // <>
                size="small"
                sx={{ ml: 2, margin: "0 16px" }}
                aria-controls={profileOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={profileOpen ? "true" : undefined}
              >
                <Avatar alt="Remy Sharp" src={""} />
              </IconButton>
            </>
          ) : (
            <S.SignupButton
              sx={{
                background:
                  service === ServiceType.CAREER_PALNNER
                    ? "#06c25e"
                    : service === ServiceType.TEAMPL
                    ? "rgb(9,191,253)"
                    : "#4c24e3",
                "&:hover": {
                  background:
                    service === ServiceType.CAREER_PALNNER
                      ? "#06c25e"
                      : service === ServiceType.TEAMPL
                      ? "rgb(9,191,253)"
                      : "#4c24e3",
                },
              }}
              onClick={() =>
                (window.location.href =
                  service === ServiceType.CAREER_PALNNER
                    ? process.env.REACT_APP_CP_LOGIN_ADDRESS
                    : service === ServiceType.TEAMPL
                    ? process.env.REACT_APP_TEAMPL_LOGIN_ADDRESS
                    : process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS)
              }
            >
              Login
            </S.SignupButton>
          )}
        </S.HeaderInnerInnerContainer>
      </S.HeaderInnerContainer>

      <ProfileMenu />
      <PaymentMenu />
    </S.HeaderContainer>
  );
}
