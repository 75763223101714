import { atom } from "jotai";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
  teamplUserAtom,
} from "../Atoms/PublicAtom";
import {
  errorModalAtom,
  noticeModalAtom,
  tossPaymentsModalAtom,
} from "../Atoms/RootAtom";
import { nanoid } from "nanoid";

const membershipRanking = ["lite", "essential", "pro", "premium"];

const cpmembershipRanking = ["basic", "standard", "professional"];

const teamplmembershipRanking = ["basic", "standard", "professional"];

export const handlePurchaseExtraDokgabiAtom = atom(
  null,
  (get, set, price: number, asset: number, chatting: number, { navigate }) => {
    const user = get(dokgabiUserAtom);

    if (user) {
      set(tossPaymentsModalAtom, {
        state: true,
        price: price,
        orderId: nanoid(),
        orderName: `어셋 ${asset}개 채팅 ${chatting}개 추가 결제`,
        customerName: user.id,
        customerEmail: user.id,
        siteName: "dokgabi",
        asset: asset,
        chat: chatting,
        planName: `어셋 ${asset}개 채팅 ${chatting}개 추가 결제`,
        payType: "extra",
      });

      navigate(
        `/checkout?price=${price}&orderName=어셋 ${asset}개 채팅 ${chatting}개 추가 결제&orderId=${nanoid()}&customerName=${
          user.id
        }&customerEmail=${
          user.id
        }&siteName=dokgabi&payType=extra&asset=${asset}&chat=${chatting}&planName=어셋 ${asset}개 채팅 ${chatting}개 추가 결제`
      );
    } else {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    }
  }
);

export const handlePurchaseDokgabiSingleButtonAtom = atom(
  null,
  async (
    get,
    set,
    price: number,
    month: number | undefined,
    name: string,
    { navigate }
  ) => {
    const user = get(dokgabiUserAtom);

    if (user) {
      // 선택한 플랜
      const selectRank = membershipRanking.findIndex(
        (value, index) => value === name
      );

      // 현재 내 플랜
      const currentRank = membershipRanking.findIndex(
        (value, index) => value === user.membership
      );

      // 그냥 바로 결제하면 되는 경우
      if (
        user.membership === "free" ||
        new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0) ||
        membershipRanking.findIndex((value, index) => value === name) ===
          membershipRanking.findIndex(
            (value, index) => value === user.membership
          )
      ) {
        // 개월 수도 넣어야 됨
        if (month) {
          navigate(
            `/checkout?price=${
              price * month
            }&orderId=${nanoid()}&orderName=${name} ${month}개월&customerName=${
              user.id
            }&customerEmail=${
              user.id
            }&siteName=dokgabi&payType=single&changeType=${
              user.membership === "free" ||
              new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
                new Date().setHours(0, 0, 0, 0)
                ? "new"
                : "renewal"
            }&currentPlan=${user.membership}&newPlan=${name}&month=${month}`
          );
        } else {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "서비스를 이용할 개월 수를 선택해주세요.",
            title: "이용 개월 수 선택",
          });
        }
      }
      // 플랜 업그레이드의 경우
      else if (
        membershipRanking.findIndex((value, index) => value === name) >
        membershipRanking.findIndex((value, index) => value === user.membership)
      ) {
        set(noticeModalAtom, {
          state: true,
          event: () => {
            navigate(`/upgrade?plan=${name}&month=${month}`),
              set(noticeModalAtom, (current) => ({
                ...current,
                state: false,
              }));
          },
          eventText: "플랜 업그레이드",
          redirectUrl: "",
          text: "플랜 업그레이드는 즉시 적용되며,\n남은 기간의 비용이 정산된 후 선택하신 추가 이용 기간 요금이 함께 결제됩니다.\n더 나은 서비스 경험을 위해 업그레이드를 진행해 주세요!",
          title: "플랜 업그레이드",
        });
      }
      // 플랜 다운그레이드의 경우
      else {
        set(noticeModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text:
            "플랜 다운그레이드는 현재 플랜 사용이 끝난 후에 가능합니다.\n" +
            `${user.membership_end_date.toLocaleDateString()} 이 플랜 사용 마지막 날입니다.\n` +
            "이용 중인 서비스의 변동 사항을 확인하신 후 진행해 주세요.",
          title: "플랜 다운그레이드",
        });
      }
    } else {
      set(noticeModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
        eventText: "로그인 이동",
        redirectUrl: "",
        text: "로그인 후에 이용해주세요.",
        title: "로그인 하러가기",
      });
    }
  }
);

export const handlePurchaseCareerPlannerSingleButtonAtom = atom(
  null,
  async (
    get,
    set,
    price: number,
    month: number,
    // people: number | null,
    name: string,
    { navigate }
  ) => {
    const user = get(careerplannerUserAtom);

    if (user) {
      if (
        user.membership === "mentee" ||
        user.membership === "mentor" ||
        user.membership === "teacher"
      ) {
        set(noticeModalAtom, {
          state: true,
          event: () =>
            (window.location.href = process.env.REACT_APP_CP_DASHBOARD_ADDRESS),
          eventText: "커리어플래너로",
          redirectUrl: "",
          text: "관리자만 플랜 업그레이드 또는 연장이 가능합니다.",
          title: "관리자 문의",
        });
      } else if (
        user.membership === "free" ||
        new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0) ||
        cpmembershipRanking.findIndex((value, index) => value === name) ===
          cpmembershipRanking.findIndex(
            (value, index) => value === user.membership
          )
      ) {
        // 개월 수도 넣어야 됨
        if (month) {
          navigate(
            `/checkout?price=${
              price * month
            }&orderId=${nanoid()}&orderName=${name} ${month}개월&customerName=${
              user.id
            }&customerEmail=${
              user.id
            }&siteName=careerplanner&payType=single&changeType=${
              user.membership === "free" ||
              new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
                new Date().setHours(0, 0, 0, 0)
                ? "new"
                : "renewal"
            }&currentPlan=${user.membership}&newPlan=${name}&month=${month}`
          );
        } else {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "서비스를 이용할 개월 수를 선택해주세요.",
            title: "이용 개월 수 선택",
          });
        }
      }
      // 플랜 업그레이드의 경우
      else if (
        cpmembershipRanking.findIndex((value, index) => value === name) >
        cpmembershipRanking.findIndex(
          (value, index) => value === user.membership
        )
      ) {
        set(noticeModalAtom, {
          state: true,
          event: () => {
            navigate(`/cp-upgrade?plan=${name}&month=${month}`),
              set(noticeModalAtom, (current) => ({
                ...current,
                state: false,
              }));
          },
          eventText: "플랜 업그레이드",
          redirectUrl: "",
          text: "플랜 업그레이드는 즉시 적용되며,\n남은 기간의 비용이 정산된 후 선택하신 추가 이용 기간 요금이 함께 결제됩니다.\n더 나은 서비스 경험을 위해 업그레이드를 진행해 주세요!",
          title: "플랜 업그레이드",
        });
      }
      // 플랜 다운그레이드의 경우
      else {
        set(noticeModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text:
            "플랜 다운그레이드는 현재 플랜 사용이 끝난 후에 가능합니다.\n" +
            `${user.membership_end_date.toLocaleDateString()} 이 플랜 사용 마지막 날입니다.\n` +
            "이용 중인 서비스의 변동 사항을 확인하신 후 진행해 주세요.",
          title: "플랜 다운그레이드",
        });
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_CP_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    }
  }
);

export const handlePurchaseTeamplSingleButtonAtom = atom(
  null,
  async (
    get,
    set,
    price: number,
    month: number,
    // people: number | null,
    name: string,
    { navigate }
  ) => {
    const user = get(teamplUserAtom);

    if (user) {
      if (
        user.membership === "free" ||
        new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0) ||
        teamplmembershipRanking.findIndex((value, index) => value === name) ===
          teamplmembershipRanking.findIndex(
            (value, index) => value === user.membership
          )
      ) {
        // 개월 수도 넣어야 됨
        if (month) {
          navigate(
            `/checkout?price=${
              price * month
            }&orderId=${nanoid()}&orderName=${name} ${month}개월&customerName=${
              user.id
            }&customerEmail=${
              user.id
            }&siteName=teampl&payType=single&changeType=${
              user.membership === "free" ||
              new Date(user?.membership_end_date ?? 0).setHours(0, 0, 0, 0) <=
                new Date().setHours(0, 0, 0, 0)
                ? "new"
                : "renewal"
            }&currentPlan=${user.membership}&newPlan=${name}&month=${month}`
          );
        } else {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "서비스를 이용할 개월 수를 선택해주세요.",
            title: "이용 개월 수 선택",
          });
        }
      }
      // 플랜 업그레이드의 경우
      else if (
        teamplmembershipRanking.findIndex((value, index) => value === name) >
        teamplmembershipRanking.findIndex(
          (value, index) => value === user.membership
        )
      ) {
        set(noticeModalAtom, {
          state: true,
          event: () => {
            navigate(`/tl-upgrade?plan=${name}&month=${month}`),
              set(noticeModalAtom, (current) => ({
                ...current,
                state: false,
              }));
          },
          eventText: "플랜 업그레이드",
          redirectUrl: "",
          text: "플랜 업그레이드는 즉시 적용되며,\n남은 기간의 비용이 정산된 후 선택하신 추가 이용 기간 요금이 함께 결제됩니다.\n더 나은 서비스 경험을 위해 업그레이드를 진행해 주세요!",
          title: "플랜 업그레이드",
        });
      }
      // 플랜 다운그레이드의 경우
      else {
        set(noticeModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text:
            "플랜 다운그레이드는 현재 플랜 사용이 끝난 후에 가능합니다.\n" +
            `${user.membership_end_date.toLocaleDateString()} 이 플랜 사용 마지막 날입니다.\n` +
            "이용 중인 서비스의 변동 사항을 확인하신 후 진행해 주세요.",
          title: "플랜 다운그레이드",
        });
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_TEAMPL_LOGIN_ADDRESS),
        eventText: "로그인 하러가기",
        redirectUrl: "",
        text: "로그인 후에 상품을 구매해주세요.",
        title: "로그인 후 이용가능",
      });
    }
  }
);
