import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ServiceType } from "../../Enum/BillingTypeEnum";
import { useAtom } from "jotai";
import { currentPayServiceAtom } from "../../Atoms/PlanTableAtom";

export function Footer() {
  const [service] = useAtom(currentPayServiceAtom);
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: { xs: "90%", sm: "80%", md: "60%" } }}
      >
        {/* 1. 첫 번째 줄 (로고 + 주소) */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
        >
          {/* 로고 */}
          <Box
            component={"img"}
            src={
              service === ServiceType.CAREER_PALNNER
                ? "/careerplanner.png"
                : service === ServiceType.TEAMPL
                ? "/teampl.png"
                : "/favicon.ico"
            }
            sx={{
              width: { xs: "20px", sm: "25px" },
              height: { xs: "20px", sm: "25px" },
              paddingRight: { xs: 0, sm: "20px" },
              borderRight: { xs: "none", sm: "1px solid #ececec" },
            }}
          />
          {/* 회사 주소 */}
          <Stack
            direction={"column"}
            sx={{
              flex: 5,
              paddingLeft: { xs: 0, sm: "20px" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "10px", md: "12px" },
                color: "#8f8f8f",
              }}
            >
              24th floor of the downtown airport tower, 36, Teheran-ro 87-gil,
              Gangnam-gu, Seoul
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "9px", sm: "10px", md: "12px" },
                color: "#8f8f8f",
              }}
            >
              © Copyright 2024 Epreep Inc., Ltd - All Rights Reserved
            </Typography>
          </Stack>
        </Stack>

        {/* 2. 두 번째 줄 (회사 정보) */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ marginTop: "5px" }}
          spacing={2}
          alignItems="center"
        >
          <Stack
            direction={"column"}
            sx={{ flex: 2, textAlign: { xs: "center", sm: "left" } }}
          >
            <Typography
              sx={{ fontSize: { xs: "9px", sm: "10px" }, color: "#8f8f8f" }}
            >
              A personal information protection manager : JANGHUN JEONG
            </Typography>
          </Stack>
          <Stack direction={"column"} sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "9px", sm: "10px" }, color: "#8f8f8f" }}
            >
              Tel : +82-2-523-7727
            </Typography>
          </Stack>
          <Stack direction={"column"} sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "9px", sm: "10px" }, color: "#8f8f8f" }}
            >
              Business Name : Epreep Inc.
            </Typography>
          </Stack>
          <Stack direction={"column"} sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "9px", sm: "10px" }, color: "#8f8f8f" }}
            >
              CEO : OKHEE KWAK
            </Typography>
          </Stack>
        </Stack>

        {/* 3. 세 번째 줄 (약관 및 개인정보) */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ marginTop: "5px", marginBottom: "10px" }}
          spacing={2}
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: { xs: "9px", sm: "10px" },
              color: "#8f8f8f",
              textAlign: "center",
            }}
          >
            Consent to overseas transfer of personal information
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "9px", sm: "10px" },
              color: "#8f8f8f",
              textAlign: "center",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() =>
              (window.location.href = "https://epreep.com/terms.html")
            }
          >
            Terms & Conditions
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "9px", sm: "10px" },
              color: "#8f8f8f",
              textAlign: "center",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/refund-policy")}
          >
            Refund Policy
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "9px", sm: "10px" },
              color: "#8f8f8f",
              textAlign: "center",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() =>
              (window.location.href = "https://epreep.com/privacy.html")
            }
          >
            Privacy Policy
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "9px", sm: "10px" },
              color: "#8f8f8f",
              textAlign: "center",
            }}
          >
            Personal Information Collection and Usage Agreement
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
