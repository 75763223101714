import { Box, Typography, Paper, Button } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dokgabiUserAtom } from "../Atoms/PublicAtom";

const price = [
  { plan: "lite", price: 10000 },
  { plan: "essential", price: 150000 },
  { plan: "pro", price: 600000 },
  { plan: "premium", price: 2000000 },
  { plan: "basic", price: 10000 },
  { plan: "standard", price: 29800 },
  { plan: "professional", price: 150000 },
];

export function Refund() {
  const [user] = useAtom(dokgabiUserAtom);

  const [refundAmount, setRefundAmount] = useState(0);
  const [remainingDays, setRemainingDays] = useState(0);
  const [daysUsed, setDaysUsed] = useState(0);
  const [overuseAmount, setOveruseAmount] = useState(0);
  const [finalPaymentAmount, setFinalPaymentAmount] = useState(0);

  useEffect(() => {
    if (user) {
      const currentPlan = price.find((value) => value.plan === user.membership);

      if (!currentPlan) return;

      const membershipEndDate = new Date(user.membership_end_date);
      const todayDate = new Date();

      // 사용된 일수 계산
      const totalDaysPassed = Math.floor(
        (todayDate.getTime() -
          membershipEndDate.getTime() +
          30 * 24 * 60 * 60 * 1000) /
          (1000 * 60 * 60 * 24)
      );

      setDaysUsed(totalDaysPassed);

      // 남은 일수 계산
      const remainingDays = 30 - totalDaysPassed;
      setRemainingDays(remainingDays);

      let calculatedRefund = 0;

      if (totalDaysPassed >= 16) {
        // 16일 이상 사용 시 환불 불가
        calculatedRefund = 0;
      } else {
        // 16일 미만 사용 시 환불 공식 적용
        const totalPrice = currentPlan.price;
        const feeAdjustedPrice = totalPrice * 0.8; // 카드 수수료 및 위약금 포함 (20% 차감)
        calculatedRefund =
          (feeAdjustedPrice / 30) * remainingDays - overuseAmount;
      }

      // 환불 금액이 0원 이하이면 추가 요금이 발생할 수도 있음
      setRefundAmount(Math.max(calculatedRefund, 0));

      // 📌 최종 결제 금액 계산
      setFinalPaymentAmount(-calculatedRefund);
    }
  }, [user]);

  if (user === null) return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 56px)",
        background: "#f5f5f5",
      }}
    >
      <Paper
        sx={{
          maxWidth: "400px",
          width: "100%",
          padding: "24px",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          background: "white",
          textAlign: "center",
          border: "1px solid #8f8f8f",
        }}
      >
        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          현재 사용 중인 플랜
        </Typography>
        <Typography fontSize="12px">
          🎫 {user.membership} (월{" "}
          {price
            .find((p) => p.plan === user.membership)
            ?.price.toLocaleString()}
          원)
        </Typography>
        <Typography fontSize="12px" sx={{ mb: 2 }}>
          📅 {new Date(user.membership_end_date).toLocaleDateString()}까지 이용
          가능
        </Typography>

        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mt: 3, mb: 1 }}
        >
          환불 계산
        </Typography>
        <Typography fontSize="12px">
          📌 사용한 기간: {daysUsed}일 (16일 이상 사용 시 환불 불가)
        </Typography>
        <Typography fontSize="12px">💰 남은 기간: {remainingDays}일</Typography>
        <Typography fontSize="12px">
          💳 환불 금액: {refundAmount.toLocaleString()}원
        </Typography>

        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="bold"
          sx={{ mt: 3, mb: 1, color: "#d32f2f" }}
        >
          🔔 환불 정책 안내
        </Typography>
        <Typography fontSize="10px" sx={{ textAlign: "left" }}>
          - 결제 후 15일까지 사용 시 남은 기간에 대한 일할 계산으로 환불됩니다.{" "}
          <br />
          - 사용 기간이 16일 이상일 경우 환불이 불가능합니다. <br />
          - 환불 금액 = (월 이용료 × 0.8 ÷ 30) × 남은 일수 - 초과 사용량 <br />-
          초과 사용량이 있을 경우 환불 금액에서 차감되며, 0원 이하일 경우 추가
          비용이 발생할 수 있습니다.
        </Typography>

        <Typography
          fontSize="14px"
          fontWeight="bold"
          sx={{ color: "#4c24e3", mt: 2 }}
        >
          💵 최종 결제 금액: {finalPaymentAmount.toLocaleString()}원
        </Typography>

        <Button
          sx={{ width: "100%", padding: "10px", marginTop: "30px" }}
          variant="contained"
        >
          {finalPaymentAmount < 0 ? "환불받기" : "플랜 다운그레이드"}
        </Button>
      </Paper>
    </Box>
  );
}
