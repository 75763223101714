import React, { useState } from "react";
import { useAtom } from "jotai";
import { currentPayServiceAtom } from "../Atoms/PlanTableAtom";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { usePayOptions } from "../Initialize/PayOptions";
import { ServiceType } from "../Enum/BillingTypeEnum";
import { CareerPlannerPlan } from "../Components/PlanTable/CareerPlannerPlan";
import { DokgabiPlan } from "../Components/PlanTable/DokgabiPlan";
import { TeamplPlan } from "../Components/PlanTable/TeamplPlan";

export function PlanTable() {
  const [service, setService] = useAtom(currentPayServiceAtom);

  const handleChange = (event: any, newValue: number) => {
    setService(newValue);
  };

  const payOptions = usePayOptions();

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "calc(100vh - 56px)",
        flexDirection: "column",
        background: "#ececec",
      }}
    >
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <Tabs
          value={service}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor:
                service === ServiceType.CAREER_PALNNER
                  ? "#06c25e"
                  : service === ServiceType.TEAMPL
                  ? "rgb(9,191,253)"
                  : "#4c24e3",
            },
            "& .MuiTab-root.Mui-selected": {
              color:
                service === ServiceType.CAREER_PALNNER
                  ? "#06c25e"
                  : service === ServiceType.TEAMPL
                  ? "rgb(9,191,253)"
                  : "#4c24e3",
              fontWeight: "bold",
            },
          }}
        >
          {payOptions.payTabName.map((value, index) =>
            value === ServiceType.CAREER_PALNNER ? (
              <Tab
                label={"커리어플래너"}
                id={`pricing-tab-${index}`}
                aria-controls={`pricing-tabpanel-${index}`}
              />
            ) : value === ServiceType.TEAMPL ? (
              <Tab
                label={"팀플"}
                id={`pricing-tab-${index}`}
                aria-controls={`pricing-tabpanel-${index}`}
              />
            ) : (
              <Tab
                label={"독갑이™"}
                id={`pricing-tab-${index}`}
                aria-controls={`pricing-tabpanel-${index}`}
              />
            )
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {payOptions.payTabName.map((value, index) => (
          <Box
            role="tabpanel"
            hidden={service !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ width: "100%", background: "#ececec" }}
          >
            {index === ServiceType.CAREER_PALNNER ? (
              <CareerPlannerPlan />
            ) : value === ServiceType.TEAMPL ? (
              <TeamplPlan />
            ) : (
              <DokgabiPlan />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
