import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import * as S from "./Components/Root/styled";
import { useAtom } from "jotai";
import { checkCookieAndAutoLoginAtom } from "./ViewModels/RootViewModel";
import { Header } from "./Components/Root/Header";
import SuccessModal from "./Components/Root/Modal/SuccessModal";
import ErrorModal from "./Components/Root/Modal/ErrorModal";
import WaitingModal from "./Components/Root/Modal/WaitingModal";
import NoticeModal from "./Components/Root/Modal/NoticeModal";
import { CustomDrawer } from "./Components/Root/Header/Drawer";
import EmailModal from "./Components/Root/Modal/EmailModal";
import { Footer } from "./Components/Footer";

function Root() {
  const [, checkCookieAndAutoLogin] = useAtom(checkCookieAndAutoLoginAtom);

  useEffect(() => {
    checkCookieAndAutoLogin();
  }, []);

  return (
    <S.FullContainer>
      <SuccessModal />
      <ErrorModal />
      <WaitingModal />
      <NoticeModal />
      <EmailModal />
      <CustomDrawer />
      <Header />
      <S.ContentContainer>
        <Outlet />
        <Footer />
      </S.ContentContainer>
    </S.FullContainer>
  );
}

export default Root;
