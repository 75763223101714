import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  MenuItem,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import {
  careerplannerUserAtom,
  dokgabiUserAtom,
} from "../../../../Atoms/PublicAtom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import * as S from "./styled";
import { setStateDrawerAtom } from "../../../../ViewModels/HeaderViewModel";
import {
  handleCpPaymentClickAtom,
  handleDgPaymentClickAtom,
  handleTlPaymentClickAtom,
} from "../../../../ViewModels/PaymentViewModel";
import { drawerOpenAtom } from "../../../../Atoms/HeaderAtom";

export function CustomDrawer() {
  const [drawerOpen, toggleDrawer] = useAtom(drawerOpenAtom);
  const [user] = useAtom(dokgabiUserAtom);
  const [cpUser] = useAtom(careerplannerUserAtom);
  const [, handleCpPaymentClick] = useAtom(handleCpPaymentClickAtom);
  const [, handleTlPaymentClick] = useAtom(handleTlPaymentClickAtom);
  const [, handleDgPaymentClick] = useAtom(handleDgPaymentClickAtom);

  const navigate = useNavigate();

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClick={() => toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "280px",
          padding: "16px",
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          메뉴
        </Typography>
        <IconButton onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider sx={{ marginY: "12px" }} />

      {/* 챗봇 빌더 회원 정보 */}
      {user && (
        <Box>
          <Typography fontSize={"12px"} color={"#8f8f8f"} marginBottom={"5px"}>
            챗봇 빌더 회원 정보
          </Typography>
          <MenuItem
            onClick={() =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS)
            }
            sx={{
              display: "flex",
              gap: 2,
              paddingY: "8px",
              borderRadius: "8px",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <Avatar
              alt="User Profile"
              src={
                user?.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
              }
            />
            <Box>
              <Typography fontSize={"14px"} fontWeight="bold">
                {user?.id}
              </Typography>
              <Typography fontSize={"12px"} color="text.secondary">
                {user?.membership}
              </Typography>
            </Box>
          </MenuItem>
        </Box>
      )}

      {/* 커리어플래너 회원 정보 */}
      {cpUser && (
        <Box>
          <Divider sx={{ marginY: "12px" }} />
          <Typography fontSize={"12px"} color={"#8f8f8f"} marginBottom={"5px"}>
            커리어플래너 회원 정보
          </Typography>
          <MenuItem
            onClick={() =>
              (window.location.href =
                process.env.REACT_APP_CP_DASHBOARD_ADDRESS)
            }
            sx={{
              display: "flex",
              gap: 2,
              paddingY: "8px",
              borderRadius: "8px",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <Avatar
              alt="User Profile"
              src={
                cpUser?.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${cpUser?.image}`
              }
            />
            <Box>
              <Typography fontSize={"14px"} fontWeight="bold">
                {cpUser?.id}
              </Typography>
              <Typography fontSize={"12px"} color="text.secondary">
                {cpUser?.membership}
              </Typography>
            </Box>
          </MenuItem>
        </Box>
      )}

      <Divider sx={{ marginY: "12px" }} />

      {/* 요금제 메뉴 */}
      <List>
        <ListItem
          onClick={() => handleCpPaymentClick({ navigate })}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Typography fontSize="14px">커리어플래너 요금제</Typography>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItem>

        <ListItem
          onClick={() => handleTlPaymentClick({ navigate })}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Typography fontSize="14px">팀플 요금제</Typography>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItem>

        <ListItem
          onClick={() => handleDgPaymentClick({ navigate })}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Typography fontSize="14px">챗봇빌더 요금제</Typography>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItem>

        <ListItem
          onClick={() =>
            (window.location.href = cpUser
              ? process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS
              : process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS)
          }
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Typography fontSize="14px">독갑이™ 으로</Typography>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItem>

        <ListItem
          onClick={() =>
            (window.location.href = cpUser
              ? process.env.REACT_APP_CP_DASHBOARD_ADDRESS
              : process.env.REACT_APP_CP_LOGIN_ADDRESS)
          }
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Typography fontSize="14px">커리어플래너로</Typography>
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItem>
      </List>
    </Drawer>
  );
}
