import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  useMediaQuery,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CheckCircle,
} from "@mui/icons-material";

// 플랜명과 가격
const PLAN_NAMES = ["Lite", "Essential", "Pro", "Premium"];
const PLAN_PRICES = ["₩10,000", "₩150,000", "₩600,000", "₩2,000,000"];

// Y를 체크 아이콘으로 변환하는 함수
const formatValue = (value: string) => {
  return value === "Y" ? <CheckCircle sx={{ color: "green" }} /> : value || "-";
};

// 데이터 섹션
const planData = [
  {
    category: "기본 제공 (퍼블리싱)",
    items: [
      { label: "만들 수 있는 챗봇 수", values: ["1개", "2개", "2개", "4개"] },
      { label: "유저 수", values: ["30명", "30명", "120명", "500명"] },
      { label: "관리자 수", values: ["1명", "3명", "3명", "5명"] },
      { label: "무료 챗봇", values: ["1개", "1개", "1개", "1개"] },
      { label: "무료 캐릭터 말투", values: ["-", "31개", "31개", "31개"] },
      { label: "무료 Asset 세트", values: ["-", "3개", "31개", "56개"] },
    ],
  },
  {
    category: "Optional (단독 호스팅)",
    items: [
      {
        label: "2vCore/4GB",
        values: ["-", "-", "IaaS(320,000원/월)\nPaaS(660,000원/월)", "-"],
      },
      {
        label: "4vCore/8GB",
        values: ["-", "-", "-", "IaaS(600,000원/월)\nPaaS(980,000원/월)"],
      },
    ],
  },
  {
    category: "Optional (온보딩)",
    items: [
      { label: "기술지원", values: ["-", "-", "600,000원/월", "600,000원/월"] },
      {
        label: "서비스지원",
        values: ["-", "-", "600,000원/월", "600,000원/월"],
      },
    ],
  },
  {
    category: "종량제",
    items: [
      {
        label: "대화수",
        values: ["10원/대화", "10원/대화", "10원/대화", "10원/대화"],
      },
      {
        label: "Asset 수",
        values: [
          "0.45원/Asset",
          "0.45원/Asset",
          "0.45원/Asset",
          "0.45원/Asset",
        ],
      },
    ],
  },
];

// 공통 열 그룹 설정
function FiveColumnGroup() {
  return (
    <colgroup>
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
      <col style={{ width: "20%" }} />
    </colgroup>
  );
}

// 플랜 테이블 컴포넌트
export function DokgabiPlanTable() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [activePlan, setActivePlan] = useState(0);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1376px",
        margin: "0 auto",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
        overflowX: "auto",
        boxSizing: "border-box",
      }}
    >
      {/* 📌 모바일: 플랜 선택 버튼 */}
      {isMobile ? (
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <IconButton
            onClick={() => setActivePlan((prev) => Math.max(prev - 1, 0))}
            disabled={activePlan === 0}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" display="inline" sx={{ fontWeight: "bold" }}>
            {PLAN_NAMES[activePlan]}
          </Typography>
          <IconButton
            onClick={() =>
              setActivePlan((prev) => Math.min(prev + 1, PLAN_NAMES.length - 1))
            }
            disabled={activePlan === PLAN_NAMES.length - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
      ) : (
        <>
          {/* 플랜명과 가격 */}
          <Table
            sx={{
              tableLayout: "fixed",
              minWidth: isMobile ? "600px" : "100%", // ✅ 모바일에서는 최소 가로폭 설정
            }}
          >
            <FiveColumnGroup />
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: isMobile ? "14px" : "16px", // ✅ 모바일에서는 폰트 크기 축소
                  }}
                >
                  플랜명
                </TableCell>
                {PLAN_NAMES.map((planName, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    {planName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  가격
                </TableCell>
                {PLAN_PRICES.map((price, idx) => (
                  <TableCell key={idx} sx={{ textAlign: "center" }}>
                    {price}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>

          {/* 섹션별 아코디언 */}
          {planData.map((section, idx) => (
            <Accordion key={idx} defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    width: "100%",
                    padding: "5px 10px",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  {section.category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table sx={{ tableLayout: "fixed", minWidth: "600px" }}>
                  <FiveColumnGroup />
                  <TableBody>
                    {section.items.map((row, rowIdx) => (
                      <TableRow
                        key={rowIdx}
                        sx={{
                          backgroundColor:
                            rowIdx % 2 === 0 ? "#f9f9f9" : "#ffffff",
                        }}
                      >
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {row.label}
                        </TableCell>
                        {row.values.map((val, valIdx) => (
                          <TableCell key={valIdx} sx={{ textAlign: "center" }}>
                            {formatValue(val)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      {/* 📌 모바일: 선택된 플랜의 상세 정보 */}
      {isMobile ? (
        <Box mt={2}>
          <List>
            {planData.map((section, idx) => (
              <Box key={idx} mt={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "2px solid #ccc",
                    pb: 1,
                    textAlign: "left",
                  }}
                >
                  {section.category}
                </Typography>
                {section.items.map((row, rowIdx) => (
                  <ListItem
                    key={rowIdx}
                    sx={{
                      display: "flex",
                      flexDirection: "column", // ✅ 세로 정렬로 변경하여 긴 텍스트 대응
                      alignItems: "flex-start",
                      paddingY: "8px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <ListItemText
                      primary={row.label}
                      sx={{
                        fontWeight: "normal",
                        whiteSpace: "normal", // ✅ 긴 텍스트 줄바꿈 가능하게 설정
                        wordBreak: "break-word", // ✅ 강제 줄바꿈
                        maxWidth: "100%", // ✅ 최대 폭 유지
                        color: "#8f8f8f",
                        fontSize: "12px",
                      }}
                    />
                    <Typography
                      sx={{
                        marginTop: "4px", // ✅ 키와 값 사이의 간격 조정
                        textAlign: "left",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        maxWidth: "100%",
                      }}
                    >
                      {formatValue(row.values[activePlan])}
                    </Typography>
                  </ListItem>
                ))}
              </Box>
            ))}
          </List>
        </Box>
      ) : null}
    </Box>
  );
}
