import { atom } from "jotai";
import { careerplannerUserAtom, dokgabiUserAtom } from "../Atoms/PublicAtom";
import {
  errorModalAtom,
  initializeTossPaymentsModal,
  successModalAtom,
  tossPaymentsModalAtom,
  TossPaymentsModalProps,
} from "../Atoms/RootAtom";
import {
  insertPayLog,
  updateCareerplannerPlan,
  updateCpOrganizerPlan,
  updatePlan,
  updateUsage,
} from "../Queries/UserQueries";
import { getCookie } from "../Utils/CookieUtil";
import { ResponseStatus } from "../Enum/NetworkEnum";
import { getVerifyRefreshToken } from "../Queries/AuthQueries";

export const handleDokgabiExtraAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: {
      payType: string;
      siteName: string;
      changeType: string;
      currentPlan: string;
      newPlan: string;
      month: number;
      asset: number;
      chat: number;
      people: number;
      organizationName: string;
    },
    response: any
  ) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const result = await updateUsage(
        accessCookie ?? "",
        get(dokgabiUserAtom)?.id ?? "",
        tossPaymentsState.asset ?? 0,
        tossPaymentsState.chat ?? 0
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "dokgabi"
        );
        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updateUsage(
            newAccessToken,
            get(dokgabiUserAtom)?.id ?? "",
            tossPaymentsState.asset ?? 0,
            tossPaymentsState.chat ?? 0
          );

          if (againUpdatePlan === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: response.totalAmount,
              order_id: response.orderId,
              purchase_name: response.orderName,
              user_id: get(dokgabiUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.type ?? "",
              site_name: "dokgabi",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(successModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 성공하셨습니다.",
              //   title: "결제 성공",
              // });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        }
      } else if (result === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: response.totalAmount,
          order_id: response.orderId,
          purchase_name: response.orderName,
          user_id: get(dokgabiUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.type ?? "",
          site_name: "dokgabi",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(successModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 성공하셨습니다.",
          //   title: "결제 성공",
          // });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);

        // set(errorModalAtom, {
        //   state: true,
        //   event: null,
        //   eventText: "",
        //   redirectUrl: "",
        //   text: "결제에 실패하셨습니다.",
        //   title: "결제 실패",
        // });
      }
    }
  }
);

export const handleDokgabiSinglePaymentAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: {
      payType: string;
      siteName: string;
      changeType: string;
      currentPlan: string;
      newPlan: string;
      month: number;
      asset: number;
      chat: number;
      people: number;
      organizationName: string;
    },
    response: any
  ) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const result = await updatePlan(
        accessCookie ?? "",
        get(dokgabiUserAtom)?.id ?? "",
        tossPaymentsState.newPlan ?? "",
        tossPaymentsState.month ?? 0
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "dokgabi"
        );

        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updatePlan(
            newAccessToken,
            get(dokgabiUserAtom)?.id ?? "",
            tossPaymentsState.newPlan ?? "",
            tossPaymentsState.month ?? 0
          );

          if (againUpdatePlan === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: response.totalAmount,
              order_id: response.orderId,
              purchase_name: response.orderName,
              user_id: get(dokgabiUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.type ?? "",
              site_name: "dokgabi",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(successModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 성공하셨습니다.",
              //   title: "결제 성공",
              // });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        } else {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(errorModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 실패하셨습니다.",
          //   title: "결제 실패",
          // });
        }
      } else if (result === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: response.totalAmount,
          order_id: response.orderId,
          purchase_name: response.orderName,
          user_id: get(dokgabiUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.type ?? "",
          site_name: "dokgabi",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(successModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 성공하셨습니다.",
          //   title: "결제 성공",
          // });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);

        // set(errorModalAtom, {
        //   state: true,
        //   event: null,
        //   eventText: "",
        //   redirectUrl: "",
        //   text: "결제에 실패하셨습니다.",
        //   title: "결제 실패",
        // });
      }
    }
  }
);

export const handleCareerplannerSinglePaymentAtom = atom(
  null,
  async (
    get,
    set,
    tossPaymentsState: {
      payType: string;
      siteName: string;
      changeType: string;
      currentPlan: string;
      newPlan: string;
      month: number;
      asset: number;
      chat: number;
      people: number;
      organizationName: string;
    },
    response: any
  ) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const result = await updateCareerplannerPlan(
        accessCookie ?? "",
        get(careerplannerUserAtom)?.id ?? "",
        tossPaymentsState.newPlan ?? "",
        tossPaymentsState.month ?? 0
      );

      if (result === ResponseStatus.FORBIDDEN) {
        const newAccessToken = await getVerifyRefreshToken(
          refreshCookie,
          "careerplanner"
        );

        if (
          typeof newAccessToken === "string" &&
          newAccessToken !== ResponseStatus.ERROR
        ) {
          const againUpdatePlan = await updateCareerplannerPlan(
            newAccessToken,
            get(careerplannerUserAtom)?.id ?? "",
            tossPaymentsState.newPlan ?? "",
            tossPaymentsState.month ?? 0
          );

          if (againUpdatePlan === true) {
            const insertPayLog2 = await insertPayLog(newAccessToken, {
              price: response.totalAmount,
              order_id: response.orderId,
              purchase_name: response.orderName,
              user_id: get(careerplannerUserAtom)?.id ?? "",
              payment_key: response.paymentKey ?? "",
              payment_type: response.type ?? "",
              site_name: "careerplanner",
            });

            if (insertPayLog2 === true) {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(successModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 성공하셨습니다.",
              //   title: "결제 성공",
              // });
            } else {
              set(tossPaymentsModalAtom, initializeTossPaymentsModal);

              // set(errorModalAtom, {
              //   state: true,
              //   event: null,
              //   eventText: "",
              //   redirectUrl: "",
              //   text: "결제에 실패하셨습니다.",
              //   title: "결제 실패",
              // });
            }
          } else {
            set(tossPaymentsModalAtom, initializeTossPaymentsModal);

            // set(errorModalAtom, {
            //   state: true,
            //   event: null,
            //   eventText: "",
            //   redirectUrl: "",
            //   text: "결제에 실패하셨습니다.",
            //   title: "결제 실패",
            // });
          }
        } else {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(errorModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 실패하셨습니다.",
          //   title: "결제 실패",
          // });
        }
      } else if (result === true) {
        const insertPayLog1 = await insertPayLog(accessCookie ?? "", {
          price: response.totalAmount,
          order_id: response.orderId,
          purchase_name: response.orderName,
          user_id: get(careerplannerUserAtom)?.id ?? "",
          payment_key: response.paymentKey ?? "",
          payment_type: response.type ?? "",
          site_name: "careerplanner",
        });

        if (insertPayLog1 === true) {
          set(tossPaymentsModalAtom, initializeTossPaymentsModal);

          // set(successModalAtom, {
          //   state: true,
          //   event: null,
          //   eventText: "",
          //   redirectUrl: "",
          //   text: "결제에 성공하셨습니다.",
          //   title: "결제 성공",
          // });
        }
      } else {
        set(tossPaymentsModalAtom, initializeTossPaymentsModal);

        // set(errorModalAtom, {
        //   state: true,
        //   event: null,
        //   eventText: "",
        //   redirectUrl: "",
        //   text: "결제에 실패하셨습니다.",
        //   title: "결제 실패",
        // });
      }
    }
  }
);
